import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import Fren from "../core/lang/fr-fr.json";
import English from "../core/lang/en-us.json";
import Englishgb from "../core/lang/en-gb.json";
import German from "../core/lang/de-de.json";
import Spanish from "../core/lang/es-us.json";
import Chinese from "../core/lang/zh-cn.json";
import Russian from "../core/lang/ru-ru.json";
import Italian from "../core/lang/it-it.json";

export const Context = React.createContext();

const Wrapper = (props) => {
  let langCode;
  let langName;
  let [local, setLocal] = useState("en-us");
  const [messages, setMessages] = useState(English);
  function setAttr() {
    let getLangs;
    if (
      document.getElementsByTagName("bsi-product-locator").length ||
      document.getElementsByTagName("bsi-map-popup")[0]
    ) {
      getLangs =
        document.getElementsByTagName("bsi-product-locator")[0] ||
        document.getElementsByTagName("bsi-map-popup")[0];
    }
    if (
      document.getElementsByTagName("bsi-locator-buy-button").length ||
      document.getElementsByTagName("bsi-map-popup")[0]
    ) {
      getLangs =
        document.getElementsByTagName("bsi-locator-buy-button")[0] ||
        document.getElementsByTagName("bsi-map-popup")[0];
    }
    let language = getLangs.getAttribute("language");
    if (language === "fr-fr") {
      langCode = "fr-fr";
      langName = Fren;
    } else if (language === "en-us") {
      langCode = "en-us";
      langName = English;
    } else if (language === "de-de") {
      langCode = "de-de";
      langName = German;
    } else if (language === "ru-ru") {
      langCode = "ru-ru";
      langName = Russian;
    } else if (language === "es-us") {
      langCode = "es-us";
      langName = Spanish;
    } else if (language === "zh-cn") {
      langCode = "zh-cn";
      langName = Chinese;
    } else if (language === "it-it") {
      langCode = "it-it";
      langName = Italian;
    } else if (language === "en-gb") {
      langCode = "en-gb";
      langName = Englishgb;
    }else {
      langCode = "en-us";
      langName = English;
    }
    setLocal(langCode);
    setMessages(langName);
  }
  useEffect(() => {
    setAttr();
  }, []);
  return (
    <Context.Provider value={{ local }}>
      <IntlProvider messages={messages} locale={local}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default Wrapper;
